//将拦截器整体导入
import request from './interceptor.js'//导入拦截器

// 定义请求域名 
// 海外：https://wonderful9game.com/
// 国内：https://www.viptopgame.com/\

// let apiTitle = 'http://47.108.193.123:8080/';//默认值 - todo 本地环境使用
let apiTitle = 'https://www.wonderful9game.com/';//默认值
if(/viptopgame/.test(location.host)) {
  apiTitle = 'https://www.viptopgame.com/';
}
if(/tariumt/.test(location.host)) {
  apiTitle = 'https://www.tariumt.com/';
}
if(/scgame/.test(location.host)) {
    apiTitle = 'https://www.scgame.online/';
}
if(/toptopgame/.test(location.host)) {
  apiTitle = 'https://www.toptopgame.site/';
}
// 封装所有的API接口

// 获取渠道权限
export function getAuthChannel(params) {
  return request({
    url: apiTitle + 'system/authChannel',
    method: 'get',
    params: params
  })
}

// 获取全部菜单
export function getAllGameMenu(params) {
  return request({
    url: apiTitle + 'system/gameMenu/all',
    method: 'get',
    params: params
  })
}

// 获取全部游戏(未使用)
export function getAllGames() {
  return request({
    url: apiTitle + 'system/main/games',
    method: 'get'
  })
}

// 获取分页游戏数据
export function getPageGamesList(params) {
  return request({
    url: apiTitle + 'system/main/list',
    method: 'get',
    params: params,
  })
}

// 获取游戏详情页数据
export function getGamesDetials(params) {
  return request({
    url: apiTitle + 'system/main/queryD',
    method: 'get',
    params: params,
  })
}

// 获取渠道广告数据
export function getQueryByChannel(params) {
  return request({
    url: apiTitle + 'system/adResource/queryByChannel',
    method: 'get',
    params: params,
  })
}

// post 请求示例
// export function getRoles(params={}){
//   return request({
//     url:'/Roles/select',
//     method :'post',
//     params:params,
//   })
// }
