<template>
    <div class="game-list-all">
        <div class="game-body">
            <!-- <div class="game-box"> -->
                <!-- <div class="game-item" :class="item.isBig=='1' ? 'is-big' : ''" @click="goDetialFn(item.id)" :id="item.id" v-for="(item, index) in gameAllListDate" :key="index">
                    <div class="img-box">
                        <span class="is-new" v-if="item.isNew == '1'">NEW</span>
                        <img :src="item.logo" alt="" />
                    </div> 
                    <p class="flex-start">
                        <span class="game-name">{{ item.enName }}</span>
                        <van-icon v-if="item.isHot == '1'" name="fire" color="rgb(249 115 0)" />
                    </p>
                </div> -->

                <!-- 原元数据备份 -->
                <!-- <div class="game-item" @click="goDetialFn(item.id)" :id="item.id" v-for="(item, index) in gameAllListDate" :key="index" >
                    <div class="img-box">
                        <span class="is-new" v-if="item.isNew == '1'">NEW</span>
                        <img :src="item.logo" alt="" />
                    </div> 
                    <p class="flex-start">
                        <span class="game-name">{{ item.enName }}</span>
                        <van-icon v-if="item.isHot == '1'" name="fire" color="rgb(249 115 0)" />
                    </p>
                </div> -->
      <!-- </div> -->

            <!-- 扩展展示 4+2 -->
            <div class="game-line">
                <div class="game-box">
                    <div class="game-item"  v-for="(item, index) in bodyGameListDate" :key="index" :id="item.id" >
                        <a :href="`${origin}/detial.html?id=${item.id}`">
                            <div class="img-box">
                                <!-- <span class="is-new" v-if="item2.isNew == '1'">NEW</span> -->
                                <img v-if="item.isNew == '1'" class="is-new" src="../image/new-img.gif" alt="">
                                <img :src="item.logo" :class="'to-default'" alt="" />
                                <img v-if="item.isHot == '1'" class="is-hot" src="../image/new-fire-2.gif" alt="">
                            </div> 
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        name: 'gameBodyList',
        data() {
            return {
                origin: window.location.origin,
            }
        },
        created() {
            console.log('----------主体页面拿到的数据------------')
            console.log(this.gameAllListDate)
        },
        computed: {
            ...mapState(['gameAllListDate','bodyGameListDate']),
            

        },
        methods: {
            // 跳转详情页
            goDetialFn(id) {
                this.$emit('goSelectedGameDetial', id)
            },
        },
    }
</script>

<style lang="scss" scoped>
.game-list-all {
  margin-bottom: 30px;
}
/* 游戏主体 */
.game-body {
  padding: 15px 15px 0;
  .game-line {
    .game-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // 正常一行4个展示的游戏
      .game-item {
        margin: 1px 0;
        padding: 0 3px;
        //width: 25%;
        border-radius: 0.3rem;
        .one-game {
          p {
            font-size: 10px;
          }
        }
        .img-box {
          position: relative;
          .to-default {
            width: 80px;
            height: 80px;
            border: 1px solid #ddd;
          }
          .to-big {
            width: 160px;
            height: 160px;
            border: 1px solid #ddd;
          }
          .is-new {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
          }
          .is-hot {
            position: absolute;
            width: 20px;
            left: 0;
            bottom: 0;
          }
        }
        .game-name {
          margin-right: 2px;
        }
        &.is-big {
          //width: 50%;
        }

      }
    }
    // 需要放大展示，一行2个的游戏
    &.is-big {
      .game-box {
        .game-item {
          //width: 50%;
        }
      }
    }

  }

}
</style>